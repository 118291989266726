.intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin: 0;
  margin-bottom: 3px;
  color: #000000;
}

.purchaseAmount{
  width: 40%;
  .fieldLabel{
    div:first-child{
      p {
        color: #000000 !important;
      }
    }
  }
}

.productType {
  .fieldLabel{
    div:first-child{
      p {
        color: #000000 !important;
      }
    }
  }
}

.stepThree{
  display: flex;
  flex-direction: row;
  margin: 0 10px;
}

.inputPointRedeem{
  display: flex;
  flex-direction: row;
  width: 40%;
  .fieldLabel{
    width: 100%;
   p{
    color: #000000;
   }
    .flagAndMobileInitial{

      background: var(--color-whitesmoke);
      display: flex;
      align-items: center;
    }
    .flagPhilippines{
      width: 18px;
      height: 18px;
      object-fit: contain;
    }
  }
  .linkAccountButton{
    margin-top: 10px;
  }
}


.container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  min-height: 100px;
  max-width: 100%;
}

.leftCardsContainer{
  width: 100%;
  margin-right: 20px;
}

.rightCardsContainer{
  width: 70%;
  background-color: var(--color-gray);
}

.rightCardsSection{
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  min-height: 100vh;
  min-width: 400px;
  margin: 45px 50px 50px;
}

