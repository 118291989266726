.intro{
  border-bottom: 1px solid #ebeef7;
}
.headerLoader{
  text-align: center;
}
.container {
  width: 400px;
  margin-top: 10px;
  margin-bottom: 30px;
  .textfield {
    width: 200px;
  }
}

.buttons {
  height: 36px;
}

.table {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    text-align: left;
    margin-bottom: 18px;
    label:first-child {
      font-size: 14px;
      font-weight: normal;
      color: var(--color-cement);
      width: 20%;
    }
    label:last-child {
      font-size: 14px;
      font-weight: bold;
      color: var(--color-night);
      width: 80%;
    }
  }
}

.productListGas{
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  width: 100%;
  padding: 10px 20px 0 0;
}


.tableGasUpDetail {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .rowDetail:last-child{
    margin-bottom: 0;
  }
  .rowDetail {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    text-align: left;
    margin-bottom: 18px;
    .gasDetailNotActive:last-child{
      font-size: 14px;
      font-weight: 500;
      width: 60%;
      color: var(--color-cement)
    }
    .gasDetailActive:last-child{
      font-size: 14px;
      font-weight: 500;
      width: 60%;
      color: var(--color-night);
    }
    .gasDetailActiveBlue:last-child{
      font-size: 14px;
      font-weight: 500;
      width: 60%;
      color: var(--color-blue);
    }
    label:first-child {
      font-size: 14px;
      font-weight: normal;
      color: var(--color-cement);
      width: 40%;
    }
  }
}

.subtitle {
  font-size: 16px !important;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 0px;
  color: var(--color-night);
}


.cardBalance {
  font-size: 16px !important;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 0px;
  color: var(--color-night);
}

.contentCredit{
display: flex;
flex-direction: column;
  .table {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .row {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      text-align: left;
      margin-bottom: 18px;
      label:first-child {
        font-size: 14px;
        font-weight: normal;
        color: var(--color-cement);
        width: 50%;
      }
      label:last-child {
        font-size: 14px;
        font-weight: bold;
        color: var(--color-night);
        width: 50%;
      }
    }
  }
}

.detailsContainer {
  display: flex;
  flex-direction: row;
  .contentPayment {
    padding-top: 30px;
    flex: 1;
  }
  .contentDetails {
    flex: 1;
    height: 100vh;
    // margin-top: -27px;
    margin-right: -36px;
    margin-bottom: -27px;
    padding: 32px;
    border-left: 1px solid #ebeef7;
  }
  .labelFuelCode {
    font-weight: 500;
  }
  .productTypeContainer {
    display: flex;
    padding: 16px 30px 16px 20px;
    border-radius: 4px;
    border: solid 1px #e4e5ec;
    flex-direction: row;
  }

  .productTypeContainerCredit {
    display: flex;
    padding: 16px 30px 16px 20px;
    border-radius: 4px;
    border: solid 1px #e4e5ec;
    flex-direction: row;
    width: fit-content; /* Adjust width to content size */
    max-width: 100%; /* Prevent overflow if content is too large */ 
  }

  .productDetailsCredit{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .creditAmount{
      color: var(--color-mid-blue);
      font-weight: 700;
      font-size: 16px;
    }
    .creditText{
      color: var(--color-cement);
      font-size: 12px;
    }
  }
  .productTypeDetails {
    display: flex;
    flex-direction: column;
    .textTitle {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .textBalance {
      font-size: 14px;
      margin-bottom: 6px;
      color: var(--color-cement);
    }
    .textNoneZeroBalance {
      font-size: 14px;
      margin-bottom: 6px;
      color: var(--color-night);
      font-weight: bold;
    }
    .textZeroBalance {
      font-size: 14px;
      margin-bottom: 6px;
      color: var(--color-deep-red);
    }
    .textZeroBalanceMessage {
      font-size: 12px;
      margin-bottom: 6px;
      color: var(--color-deep-red);
      margin-left: 10px;
    }
  }
}

.popOver {
  display: inline-block;
  .stationListLink {
    text-decoration: underline;
    color: var(--color-blue);
    font-weight: 500;
    cursor: pointer;
  }

  .stationListLinkFleetCard {
    text-decoration: underline;
    color: var(--color-deep-red);
    font-weight: 500;
    cursor: pointer;
  }
}

.boldRed {
  color: var(--color-deep-red) !important;
  font-weight: bold;
}


.titlePayment {
  margin-bottom: 10px;
  margin-top: 0px;
}

.fuelPayment {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconPayment {
  margin-left: 10px;
  margin-right: 10px;
}

.confirmationPayment {
  text-align: center;
}
.tableContainer{
  display: flex;
  align-items: center;
  flex-direction: column;
  .tablePayment {
    margin-top: 30px;
    .rowPayment {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
      margin-bottom: 18px;
      label:first-child {
        font-size: 14px;
        font-weight: normal;
        color: var(--color-cement);
        width: 100px;
        min-width: 100px;
        margin-right: 32px;
      }
      label:last-child {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-night);
      }
    }
  }
  
}

.titleContainer {
  background-color: var(--color-gray);
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  .titleContent {
    text-align: center;
  }
  .successIcon {
    width: 79.3px;
    margin-right: 12px;
    margin-left: 12px;
  }
}

.fieldContainer > p {
  width: 25%;
  color: var(--color-purple);
}
.fieldContainer > div {
  width: 35%;
}
.fieldContainer {
  text-align: left;

  .formContainer {
    width: 250px;
  }
}

.doneButton {
  padding: 5px 20px;
  height: 36px;
  min-width: 110px;
  span {
    font-weight: 700;
    font-size: 14px;
  }
}


.discountPerLiterActive{
  font-size: "12px";
  margin-left: "5px";
  color: var(--color-night);
}

.discountPerLiterNotActive{
  font-size: "12px";
  margin-left: "5px";
  color: var(--color-cement);
}

.noteDoNotIssueReceipt{
  display: flex;
  background-color: #d5eaff;
  font-size: 22px;
  line-height: 20px;
  transition: 0.3s;
  color: #0054a7;
  padding: 20px 20px 20px 40px;
  flex-direction: row;
  gap: 20px;
  border-radius: 4px;
  margin-top: 20px;
  .warningSign{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 40px;
    margin-top: -6px;
  }
  .warningTextSection{
    display: flex;
    flex-direction: column;
    font-size: 16px;
    .warningTextSingleInvoice{
      display: flex;
      margin-bottom: 10px;
    }
    .warningTextDoNotIssue{
      display: flex;
      font-weight: bold;
     
    }
  }
}

.disabledInput {
  background-color: #f2f2f2;
  color: #808080;
  cursor: not-allowed;
  opacity: 0.7;
  pointer-events: none;
}