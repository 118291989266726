.intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin: 32px 0 16px;
}

.generateQrRow {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}