.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image {
  width: 200px;
}

.title {
  margin-bottom: 5px;
}

.content {
  max-width: 320px;
  margin: auto;
}


.table {
  margin-top: 10px;
  width: 400px;
  padding: 0 20px;
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    justify-content: center;
    .horizontalLine{
      width: 100%;
      border-bottom: 1px solid #ebeef7;
    }
    label:first-child {
      font-size: 14px;
      font-weight: normal;
      color: var(--color-night);
      margin-right: 5px;
    }
    label:last-child {
      font-size: 14px;
      font-weight: bold;
      color: var(--color-night);
    }
  }
}

.fieldContainer > p {
  width: 25%;
  color: var(--color-night);
}
.fieldContainer > div {
  width: 30%;
  p {
    width: 250px;
    color: var(--color-night);
  }
}
.fieldContainer {
  text-align: left;
  .formContainer {
    width: 250px;
  }
}

.doneButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  height: 36px;
  min-width: 110px;
  span {
    font-weight: 700;
    font-size: 14px;
  }
}

.rowDoneButton{
  display: flex;
  justify-content: center;
  align-items: center;
}