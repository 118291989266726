.qrOuterRing {
  padding: 8px;
  background-color: #eeeff6;
  border-radius: 10px;
  display: inline-block;
  position: relative;
}

.qrComponent {
  border: 1px solid black;
  background-color: #ffffff;
  padding: 8px 8px 8px;

  .qrImage {
    padding-top: 0;
    margin-bottom: 4px;
  }
}

.productName {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.templateComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 544px;
  width: 423px;
  padding: 20px;
  background-size: cover;
  background-position: center;
}

.diesel {
  background-image: url('../../../images/assets/qr-templated-diesel.png');
}
.gas91 {
  background-image: url('../../../images/assets/qr-templated-gas91.png');
}
.gas95 {
  background-image: url('../../../images/assets/qr-templated-gas95.png');
}
.gas97 {
  background-image: url('../../../images/assets/qr-templated-gas97.png');
}
.lubes {
  background-image: url('../../../images/assets/qr-templated-lubes.png');
  .qrDescription {
    padding-top: 64px;
    width: calc(100% - 32px);
  }
}

.qrImage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 322px;
  height: 150px;
}

.qrDescription {
  text-align: center;
  font-weight: bold;
  margin-left: 16px;
  margin-right: 16px;
  word-wrap: break-word;
  width: calc(100% - 32px);
  overflow: hidden;
  text-overflow: ellipsis;

  .longText {
    font-size: 18px;
    padding-top: 116px;
  }

  .shortText {
    padding-top: 130px;
    font-size: 18px;
  }

  .longLubeText {
    font-size: 15px;
    padding-top: 68px;
  }

  .shortLubeText {
    font-size: 15px;
    padding-top: 78px;
  }
}

.large {
  padding-top: 24px;
  font-size: 24px;
}
