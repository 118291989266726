.buttonContainer {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0px 70px;
  .button {
    margin-right: 20px;
    border-radius: 50px;
    font-weight: 700;
    color: var(--color-gray);
  }
  text-decoration: none;
  .active {
    color: var(--color-purple);
  }
}

.navLink {
  font-weight: 700;
  color: var(--color-night);
  text-decoration: none;
  padding: 13px 20px 12px;
  border-radius: 40px;
  border: solid 1px #afbadd;
  font-size: 12px;
}

.activeNavLink {
  color: var(--color-white) !important;
  box-shadow: 0 2px 6px 0 rgba(83, 73, 183, 0.4);
  background-color: #3571ca;
}
