
.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    min-height: 100px;
    max-width: 100%;
}

.circular{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.title{
    font-weight: bold;
}
.titleInActive {
    font-size: 16px;
    font-weight: 500;
    color: #a9acc6;
}

.titleActive {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}


.text {
    font-size: 16px;
    margin-left: 5px;
}

.row{
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.rowCard{
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: space-between;
}

.qrCodeContainer{
    height: 78px;
    margin: 20px 10px;
    border-radius: 4px;
    border: solid 1px #afbadd;
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;    
    gap: 30px;   
    .content{
        text-align: center;
    }
}


.card{
    padding: 10px 30px;
    max-width: 235px;
    border-radius: 10px;
    border-width: 2px;
    background-color: white;
    box-shadow: 0 10px 20px 0 #e8e7eb;
    .icon{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
    } 
}


.cardActive{
    background-color: #3571ca;
}

.label{
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    align-items: center;
}
.labelSelected{
    font-size: 20px;
    text-align: center;
    color: white;
    font-weight: bold;
    align-items: center;
}

.stepThree{
    display: flex;
    flex-direction: row;
    margin: 0 10px;
}
.leftCardsContainer{
    width: 100%;
    margin-right: 20px;
}

.rightCardsContainer{
    width: 70%;
    background-color: var(--color-gray);
}

.inputMobileNumber{
    display: flex;
    flex-direction: row;
    margin: 0 30px;
    align-items: center;
    justify-content: center;
    .fieldLabel{
      width: 40%;
      .flagAndMobileInitial{
        display: flex;
        align-items: center;
      }
      .flagPhilippines{
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin: 0 4px
      }
    }
    .linkAccountButton{
      margin-top: 10px;
    }
  }

.rightCardsSection{
    display: flex;
    flex-direction: column;
    gap: 20px 0;
    min-height: 100vh;
    min-width: 400px;
    margin: 45px 50px 50px;
    .vipAccount{
        border-radius: 10px;
        box-shadow: 0 10px 20px 0 #e8e7eb;
        background-color: var(--color-white);
        overflow: hidden;
        .vipLinkImage{
            display: block;
            width: 100%;
            object-fit: cover;
        }
        .viplinkContent{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: var(--color-white);
            padding: 10px 20px;
            .vipAccountDetails{
                display: flex;
                flex-direction: column;
            }
            .vipAccountPointsContainer{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .vipAccountPoints{
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: #edeff7;
                border-radius: 18px;
                padding: 20px; 
                height: 45px;
                .imageWrapper{
                    flex: 1; 
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                .imgPoint{
                    max-width: 100%;
                    border-radius: 50%; 
                }
                .textWrapper{
                    flex: 2; /* Occupy 2/5 of the space */
                    padding: 0 20px;
                    .textPointsInActive {
                        font-size: 16px;
                        line-height: 1.5;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 0 5px;
                        color: #a9acc6
                    }
                    .textPointsActive {
                        font-size: 16px;
                        line-height: 1.5;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 0 5px;
                        color: #3571ca
                    }
                }
            }
        }

        .vipBrokenLine {
            border: none;
            border-top: 1px dashed #a9acc6;
            color: #fff;
            background-color: #fff;
            height: 1px;
            margin: 20px 20px 30px 20px;
            border-width: 3px;
        }

        .vipButtons{
            display: flex;
            flex-direction: column;
            margin: 20px;
            justify-content: space-evenly;
        }
          
    }
}

.mobileNumberAreaCode{
    font-size: 14px,
}

.imageQrCode{
    width: 40px;
    height: 40px; 
}

.reScanButton{
    width: 100%;
    background-color: #e3f2fd;
    color: #2f529c;
    font-weight: bold;
}