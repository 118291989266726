.title{
    font-weight: bold;
}
.titleInActive {
    font-size: 16px;
    font-weight: 500;
    color: #a9acc6;
}

.titleActive {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}


.text {
    font-size: 16px;
    margin-left: 5px;
}



.card{
    padding: 10px 30px;
    width: 260px;
    border-radius: 10px;
    border-width: 2px;
    background-color: white;
    box-shadow: 0 10px 20px 0 #e8e7eb;
    .icon{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
    } 
}


.cardActive{
    padding: 10px 30px;
    width: 260px;
    border-radius: 10px;
    border-width: 2px;
    background-color: #3571ca;
    border: solid 1px #afbadd;
    box-shadow: 0 10px 20px 0 #e8e7eb;
    .icon{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
    } 
}

.label{
    font-size: 20px;
    text-align: center;
}
.labelSelected{
    font-size: 20px;
    text-align: center;
    color: white;
}

.stepThree{
    display: flex;
    flex-direction: row;
    margin: 0 10px;
}

.vipAccount{
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 #e8e7eb;
    background-color: var(--color-white);
    overflow: hidden;
    .vipLinkImage{
        display: block;
        width: 100%;
        object-fit: cover;
    }
    .viplinkContent{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: var(--color-white);
        padding: 10px 20px;
        .vipAccountDetails{
            display: flex;
            flex-direction: column;
        }
        .vipAccountPointsContainer{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .vipAccountPoints{
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #edeff7;
            border-radius: 18px;
            padding: 20px 5px; 
            height: 45px;
            .imageWrapper{
                flex: 1; 
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .imgPoint{
                max-width: 100%;
                border-radius: 50%; 
                height: 32px;
            }
            .textWrapper{
                flex: 2; /* Occupy 2/5 of the space */
                padding: 0 15px 0 5px;
                .textPointsInActive {
                    font-size: 12px;
                    line-height: 1.5;
                    display: flex;
                    flex-direction: row;
                    gap: 0 5px;
                    color: #a9acc6
                }

                .pointsInActive{
                    font-size: 18px;
                    color: #a9acc6;
                    font-weight: bold;
                }
                .pointsActive{
                    font-size: 18px;
                    color: #3571ca;
                    font-weight: bold ;
                }
                .textPointsActive {
                    font-size: 12px;
                    display: flex;
                    flex-direction: row;
                    gap: 0 5px;
                    color: #3571ca
                }

                .pointsActiveText {
                    font-size: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-weight: 900;
                    color: #3571ca;
              
                }

                .pointsInActiveText {
                    font-size: 10px;
                    display: flex;
                    flex-direction: column;
                    color: #a9acc6;
                    font-weight: 900;
                    justify-content: center;
                }
            }
        }
    }

    .vipBrokenLine {
        border: none;
        border-top: 1px dashed #a9acc6;
        color: #fff;
        background-color: #fff;
        height: 1px;
        margin: 20px 20px 30px 20px;
        border-width: 3px;
    }

    .vipButtons{
        display: flex;
        flex-direction: column;
        margin: 20px;
        justify-content: space-evenly;
    }
        
}

.calculatorContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    .eligibleAmountImage{
        display: block; 
        object-fit: cover;
        max-width: 30%;
        max-height: 30%;
        margin: 20px;
    }
    .eligibleAmount{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        background-color: #edeff7;
        width: 45%;
    }
    .equal{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 2rem;
    }
    .vipPointsEquivalentImage{
        display: block; 
        object-fit: cover;
        max-width: 23%;
        max-height: 23%;
        margin: 20px;
        border-radius: 7px;
    }
    .vipPointsEquivalent{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        background-color: #edeff7;
        width: 45%;
    }
}


.disabledGiveVIPPointsEquivalentLabel{
    font-size: 12px;
    color: #a7aab2;
}

.enabledGiveVIPPointsEquivalentLabel{
    font-size: 12px;
    color: #000;
}

.disabledGiveVIPPointsAmount{
    font-size: 14px;
    font-weight: 900;
    color: #a7aab2;
}

.enabledGiveVIPPointsAmount{
    font-size: 14px;
    font-weight: 900;
}

.giveVIPPointsButton{
    width: 100%;
    font-weight: bold;
}

.awardPointsButton{
    width: 100%;
    font-weight: bold;
    margin-bottom: 20px;
}

.payWithPointsButtonWithData{
    margin-bottom: 20px;
    width: 100%;
    font-weight: bold;
    background-color: #e3f2fd;
    color: #2f529c;
}

.payWithPointsButtonWithoutData{
    margin-bottom: 20px;
    width: 100%;
    font-weight: bold;
}


.payWithPointsButtonWithDataViewEarning {
    margin-bottom: 20px;
    width: 100%;
    font-weight: bold;
    background-color: #e3f2fd;
    border: 2px solid;
    border-radius: 5px;
    color: var(--color-blue);
    background: unset;
}

.payWithPointsButtonWithDataViewEarning, 
.payWithPointsButtonViewEarningWithoutData:hover {
    background-color: var(--color-baby-blue);
    box-shadow: unset;
}


.payWithPointsButtonViewEarningWithoutData{
    margin-bottom: 20px;
    width: 100%;
    font-weight: bold;
}


.awardButtonWithDataViewEarning {
    margin: 10px 0;
    width: 100%;
    font-weight: bold;
    background-color: #e3f2fd;
    border: 2px solid;
    border-radius: 5px;
    color: var(--color-blue);
    background: unset;
}

.awardButtonWithDataViewEarning, 
.awardButtonViewEarningWithoutData:hover {
    background-color: var(--color-baby-blue);
    box-shadow: unset;
}


.awardButtonViewEarningWithoutData{
    margin: 10px 0;
    width: 100%;
    font-weight: bold;
}

.vipRedemptionsButton{
    width: 100%;
    font-weight: bold;
    margin-bottom: 20px;
}

.vipAccountWithData{
    color: #000000
}

.vipAccountWithoutData{
    color: #a9acc6
}

.registerVIPCardButton{
    width: 100%;
    font-weight: bold;
    margin-bottom: 20px;
}

.vipPointsConversion{
    margin: 5px 20px; 
}