.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.filter {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.fieldContent {
  width: 260px;
}

.status {
  margin: 20px -1.2rem;
}

.search {
  width: 580px;
  margin-left: 10px;
}

.downloadButton {
  display: flex;
  justify-content: flex-end;
  width: 100px;
}
