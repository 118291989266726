.intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin: 0;
  margin-bottom: 3px;
  color: #000000;
}

.purchaseAmount{
  width: 40%;
  .fieldLabel{
    div:first-child{
      p{
        color: #000000 !important;
       }
    }
  }
}

.stepThree{
  display: flex;
  flex-direction: row;
  margin: 0 10px;
}

.inputPointRedeem{
  display: flex;
  flex-direction: row;
  width: 40%;
  .fieldLabel{
    width: 100%;
   p{
    color: #000000;
   }
    .flagAndMobileInitial{

      background: var(--color-whitesmoke);
      display: flex;
      align-items: center;
    }
    .flagPhilippines{
      width: 18px;
      height: 18px;
      object-fit: contain;
    }
  }
  .linkAccountButton{
    margin-top: 10px;
  }
}


.container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  min-height: 100px;
  max-width: 100%;
}

.leftCardsContainer{
  width: 100%;
  margin-right: 20px;
}

.pointsConversionContainer{
  width: 100%;
  margin-right: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}


/* Styles */
.pointsConversionContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.cardRedemptionActive{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px 40px 15px 15px;
  border-radius: 10px;
  border-width: 2px;
  box-shadow: 0 10px 20px 0 #e8e7eb;
  // flex-basis: calc(33.33% - 20px);
  gap: 10px;
  background-color: #ffffff;
  .cardLeft{
    margin-right: 18px;
    .inner {
      width: 130px;
      background-color: #1b305b;
      color: #fff;
      // padding: 10px;
      border-radius: 8px;
      overflow: hidden;
      .title {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        width: 100%;
        // background-color: #1b305b;
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 5px;
        color: #ffffff;
        word-break: break-all;
        height: 50px;
      }
      .cash {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        background-color: #faef6d;
        color: #1b305b;
        padding: 8px 16px;
        font-size: 14px;
        font-weight: bold;
        width: 100%; /* add this line */
        box-sizing: border-box; 
      }
    }
  } 
  .cardLeft, .cardRight {
    height: 100%;
    flex-grow: 1; /* Ensures equal space */
    .title {
      font-size: 1rem;
      font-weight: bold;
      word-break: break-all;
    }
    .vipAccountPointsContainer {
      display: flex;
      flex-direction: row;
      .vipAccountPoints {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #edeff7;
        border-radius: 18px;
        height: 24px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 2px;
        padding-bottom: 2px;
        .textWrapper {
          display: inline-block;
        }
        
        .textPointsInActive, .textPointsActive {
          font-size: 0.75rem;

          display: flex;
          flex-direction: row;
          gap: 5px;
        }
        
        .pointsInActive, .pointsActive {
          font-weight: bold;
        }
        
        .pointsInActive {
          color: #a9acc6;
        }
        
        .pointsActive {
          color: #3571ca;
        }
        
        .pointsActiveText, .pointsInActiveText {
          font-size: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-weight: 900;
        }
        
        .pointsActiveText {
          color: #3571ca;
        }
        
        .pointsInActiveText {
          color: #a9acc6;
        }
      }
    }
  }
}

.cardRedemptionActive:hover{
  cursor: pointer;
}

.cardRedemptionInActive::after{
  content: "";
  background-color: #a7aab2;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.2;
  z-index: 999;
  border-radius: 10px;
  border-width: 2px;
  left: 0px;
}
.cardRedemptionInActive{
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px 40px 15px 15px;
  border-radius: 10px;
  border-width: 2px;
  // flex-basis: calc(33.33% - 20px);
  gap: 10px;
  background-color: #edeff6;
  .cardLeft{
    margin-right: 18px;
    .inner {
      width: 130px;
      background-color: #1b305b;
      color: #fff;
      // padding: 10px;
      border-radius: 8px;
      overflow: hidden;
      .title {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        width: 100%;
        // background-color: #1b305b;
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 5px;
        color: #ffffff;
        word-break: break-all;
        height: 50px;
      }
      .cash {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        background-color: #faef6d;
        color: #1b305b;
        padding: 8px 16px;
        font-size: 14px;
        font-weight: bold;
        width: 100%; /* add this line */
        box-sizing: border-box; 
      }
    }
  } 
  
  .cardLeft, .cardRight {
    height: 100%;
    flex-grow: 1; /* Ensures equal space */
    .title {
      font-size: 1rem;
      font-weight: bold;
      word-break: break-all;
      color: #a7aab2;
    }
    .vipAccountPointsContainer {
      display: flex;
      flex-direction: row;
      .vipAccountPoints {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #a7aab2;
        border-radius: 18px;
        height: 24px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 2px;
        padding-bottom: 2px;
        .textWrapper {
          display: inline-block;
        }
        
        .textPointsInActive, .textPointsActive {
          font-size: 0.75rem;
          display: flex;
          flex-direction: row;
          gap: 5px;
        }
        
        .pointsInActive, .pointsActive {
          font-weight: bold;
        }
        
        .pointsInActive {
          color: #fff;
        }
        
        .pointsActive {
          color: #3571ca;
        }
        
        .pointsActiveText, .pointsInActiveText {
          font-size: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-weight: 900;
        }
        
        .pointsActiveText {
          color: #3571ca;
        }
        
        .pointsInActiveText {
           color: #fff;
        }
        
      }
    }
  }
}






.imgPoint {
  max-width: 100%;
  border-radius: 50%;
  height: 32px;
}


.pts {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}


.pointsText{
  color: #1976d2;
  font-weight: bold;
}
.pointsBox{
  text-align: center;
  padding-top: 32px;
}
.title{
  font-weight: bold;
}
.titleInActive {
  font-size: 16px;
  font-weight: 500;
  color: #a9acc6;
}

.titleActive {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}


.text {
  font-size: 16px;
  margin-left: 5px;
}



.card{
  padding: 10px 30px;
  width: 260px;
  border-radius: 10px;
  border-width: 2px;
  background-color: white;
  box-shadow: 0 10px 20px 0 #e8e7eb;
  .icon{
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 60%;
  } 
}


.cardActive{
  padding: 10px 30px;
  width: 260px;
  border-radius: 10px;
  border-width: 2px;
  background-color: #3571ca;
  border: solid 1px #afbadd;
  box-shadow: 0 10px 20px 0 #e8e7eb;
  .icon{
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 60%;
  } 
}

.label{
  font-size: 20px;
  text-align: center;
}
.labelSelected{
  font-size: 20px;
  text-align: center;
  color: white;
}

.stepThree{
  display: flex;
  flex-direction: row;
  margin: 0 10px;
}

.vipAccount{
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 #e8e7eb;
  background-color: var(--color-white);
  overflow: hidden;
  width:35%;
  height: 150px;
  margin-top: -80px;
  .vipLinkImage{
      display: block;
      width: 100%;
      object-fit: cover;
  }
  .viplinkContent{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: var(--color-white);
      padding: 40px;
      .vipAccountDetails{
          display: flex;
          flex-direction: column;
          
      }
      .vipAccountPointsContainer{
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 10px;
      }
      .vipAccountPoints{
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: #edeff7;
          border-radius: 18px;
          padding: 20px 5px; 
          height: 45px;
          .imageWrapper{
              flex: 1; 
              display: flex;
              flex-direction: row;
              align-items: center;
          }
          .imgPoint{
              max-width: 100%;
              border-radius: 50%; 
              height: 32px;
          }
          .textWrapper{
              flex: 2; /* Occupy 2/5 of the space */
              padding: 0 15px 0 5px;
              .textPointsInActive {
                  font-size: 12px;
                  line-height: 1.5;
                  display: flex;
                  flex-direction: row;
                  gap: 0 5px;
                  color: #a9acc6
              }

              .pointsInActive{
                  font-size: 18px;
                  color: #a9acc6;
                  font-weight: bold;
              }
              .pointsActive{
                  font-size: 18px;
                  color: #3571ca;
                  font-weight: bold ;
              }
              .textPointsActive {
                  font-size: 12px;
                  display: flex;
                  flex-direction: row;
                  gap: 0 5px;
                  color: #3571ca
              }

              .pointsActiveText {
                  font-size: 10px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  font-weight: 900;
                  color: #3571ca;
            
              }

              .pointsInActiveText {
                  font-size: 10px;
                  display: flex;
                  flex-direction: column;
                  color: #a9acc6;
                  font-weight: 900;
                  justify-content: center;
              }
          }
      }

      .vipAccountViewEarningHistory{
        display: flex;

        .redemptionButtonWithDataViewEarning {
          margin-bottom: 20px;
          width: 100%;
          font-weight: bold;
          background-color: #e3f2fd;
          border: 2px solid;
          border-radius: 5px;
          color: var(--color-blue);
          background: unset;
        }
        
        .redemptionButtonWithDataViewEarning, 
        .redemptionButtonViewEarningWithoutData:hover {
            background-color: var(--color-baby-blue);
            box-shadow: unset;
        }
        
        
        .redemptionButtonViewEarningWithoutData{
            margin-bottom: 20px;
            width: 100%;
            font-weight: bold;
        }
      }
  }

  .vipBrokenLine {
      border: none;
      border-top: 1px dashed #a9acc6;
      color: #fff;
      background-color: #fff;
      height: 1px;
      margin: 20px 20px 30px 20px;
      border-width: 3px;
  }

  .vipButtons{
      display: flex;
      flex-direction: column;
      margin: 20px;
      justify-content: space-evenly;
  }
      
}

.calculatorContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  .eligibleAmountImage{
      display: block; 
      object-fit: cover;
      max-width: 30%;
      max-height: 30%;
      margin: 20px;
  }
  .eligibleAmount{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      background-color: #edeff7;
      width: 45%;
  }
  .equal{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 2rem;
  }
  .vipPointsEquivalentImage{
      display: block; 
      object-fit: cover;
      max-width: 23%;
      max-height: 23%;
      margin: 20px;
      border-radius: 7px;
  }
  .vipPointsEquivalent{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      background-color: #edeff7;
      width: 45%;
  }
}


.disabledGiveVIPPointsEquivalentLabel{
  font-size: 12px;
  color: #a7aab2;
}

.enabledGiveVIPPointsEquivalentLabel{
  font-size: 12px;
  color: #000;
}

.disabledGiveVIPPointsAmount{
  font-size: 14px;
  font-weight: 900;
  color: #a7aab2;
}

.enabledGiveVIPPointsAmount{
  font-size: 14px;
  font-weight: 900;
}

.giveVIPPointsButton{
  width: 100%;
  font-weight: bold;
}

.awardPointsButton{
  width: 100%;
  font-weight: bold;
  margin-bottom: 20px;
}

.payWithPointsButtonWithData{
  margin-bottom: 20px;
  width: 100%;
  font-weight: bold;
  background-color: #e3f2fd;
  color: #2f529c;
}

.payWithPointsButtonWithoutData{
  margin-bottom: 20px;
  width: 100%;
  font-weight: bold;
}

.vipRedemptionsButton{
  width: 100%;
  font-weight: bold;
  margin-bottom: 20px;
}

.vipAccountWithData{
  color: #000000
}

.vipAccountWithoutData{
  color: #a9acc6
}

.registerVIPCardButton{
  width: 100%;
  font-weight: bold;
  margin-bottom: 20px;
}

.vipPointsConversion{
  margin: 5px 20px; 
}
