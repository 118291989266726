.validatePaymentContainer{
  display: flex;
  justify-content: center;
}
.headerValidateTitle{
  padding: 20px 0px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.title {
  margin-bottom: 10px;
  margin-top: 0px;
  text-align: center;
}

.fuel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.productFuel{
  font-size: 18px;
  font-weight: bold;
}

.icon {
  margin-left: 10px;
  margin-right: 10px;
}

.confirmation {
  margin-top: 10px;
  width: 700px;
}

.confirmContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.detailsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin:30px 0;
  .contentPayment {
    padding-top: 30px;
    flex: 1;
  }
  .contentDetails {
    flex: 1;
    height: 100%;
    // margin-top: -27px;
    margin-right: -36px;
    margin-bottom: -27px;
    padding: 32px;
    border-left: 1px solid #ebeef7;
    
    /* Media Query for Large screens */
    @media only screen and (min-width: 1281px) {
       height: 80%;
    }
  }
  .labelFuelCode {
    font-weight: 500;
  }
  .productTypeContainer {
    display: flex;
    padding: 16px 30px 16px 20px;
    border-radius: 4px;
    border: solid 1px #e4e5ec;
    flex-direction: row;
  }
  .productTypeDetails {
    display: flex;
    flex-direction: column;
    .textTitle {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .textBalance {
      font-size: 14px;
      margin-bottom: 6px;
      color: var(--color-cement);
    }
  }
}


.tableContainer{
  display: flex;
  align-items: center;
  flex-direction: column;
  .table {
    margin-top: 20px;
    width: 300px;
    .tableGasDetail{
      display: flex;
      flex-direction: column; 
      .row {
        display: flex;
        flex-direction: row;
        text-align: left;
        margin-bottom: 18px;
        .horizontalLine{
          width: 100%;
          border-bottom: 1px solid #ebeef7;
        }
        label:first-child {
          font-size: 14px;
          font-weight: normal;
          color: var(--color-cement);
          width: 60%;
        }
        label:last-child {
          font-size: 14px;
          font-weight: bold;
          color: var(--color-night);
          width: 40%;
          word-break: break-all;
        }
      }
    }
   
    .row {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      text-align: left;
      margin-bottom: 18px;
      label:first-child {
        font-size: 14px;
        font-weight: normal;
        color: var(--color-cement);
        width: 40%;
      }
      label:last-child {
        font-size: 14px;
        font-weight: bold;
        color: var(--color-night);
        width: 60%;
      }
    }
  }
}
.noteDoNotIssueReceiptSection{
  display: flex;
  align-items: center;
  justify-content: center;
}
.noteDoNotIssueReceipt{
  display: flex;
  background-color: #d5eaff;
  font-size: 22px;
  line-height: 20px;
  transition: 0.3s;
  color: #0054a7;
  padding: 20px 20px 20px 40px;
  flex-direction: row;
  gap: 20px;
  border-radius: 4px;
  margin-top: 20px;
  .warningSign{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 40px;
    margin-top: -6px;
  }
  .warningTextSection{
    display: flex;
    flex-direction: column;
    font-size: 16px;
    .warningTextSingleInvoice{
      display: flex;
      margin-bottom: 10px;
    }
    .warningTextDoNotIssue{
      display: flex;
      font-weight: bold;
     
    }
  }
}
