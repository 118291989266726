.fuelCard {
  display: flex;
  padding: 10px;
  border: 1px solid var(--color-ash);
  border-radius: 4px;
  cursor: pointer;
  transition: background 1s, box-shadow 1s, border-color 1s;

  &.withPrice {
    align-items: flex-start;
    justify-content: space-between;
  }

  &.disabled {
    border: none;
    background-color: var(--color-concrete);
  }
}

.fuelCard:hover {
  box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 5%);
}

.fuelCard:hover,
.fuelCard.active {
  border-color: var(--color-blue);

  background-color: var(--color-baby-blue);
}

.nameBalance {
  display: flex;
  align-items: center;
}

.price {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px;
  word-break: break-all;
  &.disabled {
    color: var(--color-cement);
  }
}

.subtitle {
  color: var(--color-cement);
}

.creditPrice {
  font-size: 14px;
}

.originalPrice {
  font-size: 12px;
  text-decoration: line-through;
}
