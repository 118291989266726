.table {
  table {
    width: 2000px;
  }
}

.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: 120px;
  color: #95a2aa;
  line-height: 1.5;
}
