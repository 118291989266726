.button {
  text-transform: none;
  min-height: 36px;
  font-weight: 400;
  padding: 10px 20px;
  box-shadow: none;
}

.primary {
  color: #ffffff;
  background: linear-gradient(to right, #0054a7 -9%, #3a8dde);
}

.circular {
  background: none;
  width: 20px !important;
  height: 20px !important;

  &.primary {
    color: #ffffff;
  }
}

.disabled {
  filter: grayscale(80%);
  color: #ffffff !important;
}

.link {
  background: unset;
  text-decoration: underline;
  text-decoration-color: var(--color-blue);
  padding: 0;
  color: var(--color-blue);
  font-weight: 500;
}

.link:hover,
.link:active {
  box-shadow: unset;
  background: unset;
  text-decoration: inherit;
}

.outline {
  background: unset;
  color: var(--color-blue);
  border: 2px solid;
  border-radius: 20px;
  padding: 7px 20px;
}


.outlineSquare {
  background: unset;
  color: var(--color-blue);
  border: 2px solid;
  border-radius: 4px;
  padding: 7px 20px;
}

.outline:hover {
  background-color: var(--color-baby-blue);
  box-shadow: unset;
}

.text {
  background: unset;
  // text-decoration: underline;
  text-decoration-color: var(--color-blue);
  padding: 0;
  color: var(--color-blue);
  font-weight: 500;

  &:hover {
    background: unset;
    box-shadow: unset;
  }
}


.exportbutton {
  background: var(--color-light-blue);
  transition: background 0.5s;
  span {
    color: var(--color-mid-blue);
    font-weight: 500;
    align-items: center;
    display: flex;
  }
  &:hover,
  &.active {
    background: var(--color-mid-blue);
    span {
      color: var(--color-white);
    }
  }
}
