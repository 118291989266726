.actionButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  .primary {
    margin-left: 10px;
  }
  button {
    font-weight: 500;
    height: 36px;
  }

  .cancel {
    padding: 10px 30px;
  }
}

.modal {
  width: 580px;
}

.content:not(.noSpace) {
  margin-top: 10px;
}

.noSpace {
  margin-top: -20px;
}

.headerReceiptGuide{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  .headerLeftPart{
    display: flex;
    flex-direction: column;
    width: 65%;
  }
}

.removeBorderButton td{
  border-bottom: none;
}

.tableReceiptGuide{
  border-radius: 10px;
  height: 280px;
  .tableHead{
    background-color: #eaf2fa;
  }
}

.vatSpace{
  padding: 5px 16px;
}

.vatSpaceFirst{
  padding: 16px 16px 5px 16px;
}

.fieldLabelGuide{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0px !important;
}

.fieldLabelGuide{
  div:nth-child(1){
    width: 70%;
    height: 100%;
  }
  div:nth-child(2){
    width: 100%;
    height: 100%;
    p{
      text-align: left;
    }
  }
}
