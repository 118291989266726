@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?xvvcdn');
  src:  url('fonts/icomoon.eot?xvvcdn#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?xvvcdn') format('truetype'),
    url('fonts/icomoon.woff?xvvcdn') format('woff'),
    url('fonts/icomoon.svg?xvvcdn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-credit-account:before {
  content: "\e916";
}
.icon-icon-fleet-activity:before {
  content: "\e900";
}
.icon-icon-fuel:before {
  content: "\e901";
}
.icon-icon-cash-in:before {
  content: "\e902";
}
.icon-icon-dashboard:before {
  content: "\e903";
}
.icon-icon-pricing:before {
  content: "\e904";
}
.icon-icon-station:before {
  content: "\e905";
}
.icon-icon-filter:before {
  content: "\e906";
}
.icon-icon-download:before {
  content: "\e907";
}
.icon-icon-grid:before {
  content: "\e908";
}
.icon-icon-add-user:before {
  content: "\e909";
}
.icon-icon-gas:before {
  content: "\e90a";
}
.icon-icon-payment:before {
  content: "\e90b";
}
.icon-icon-mall:before {
  content: "\e90c";
}
.icon-icon-pencil:before {
  content: "\e90d";
}
.icon-icon-user:before {
  content: "\e90e";
}
.icon-icon-campaigns:before {
  content: "\e90f";
}
.icon-icon-access:before {
  content: "\e910";
}
.icon-icon-gear:before {
  content: "\e911";
}
.icon-icon-clock:before {
  content: "\e913";
}
.icon-icon-help:before {
  content: "\e914";
}
.icon-qr_code_scanner:before {
  content: "\e915";
}
.icon-icon-checkmark:before {
  content: "\ea10";
}
.icon-icon-sync:before {
  content: "\ea2f";
}
.icon-icon-back:before {
  content: "\ea40";
}
.icon-icon-diamond:before {
  content: "\e912";
}
