.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: 120px;
  color: #95a2aa;
  line-height: 1.5;
}

.imageSize {
  width: 58px;
  height: 68px;
  cursor: pointer;
}

.viewPhoto {
  max-width: 700px;
  max-height: 500px;
  margin: auto;
}

.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: auto;
  color: #95a2aa;
  line-height: 1.5;
}

.inputDiv{
  display: flex;
  justify-content: space-between;
}

.emailAddresses {
  width: 100%;
}

.inputDiv >  div:nth-child(1){
  display: flex;
  align-items: center;
}

.inputDiv >  div:nth-child(2){
  width: 70%;
}
.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: 120px;
  color: #95a2aa;
  line-height: 1.5;
}

.imageSize {
  width: 58px;
  height: 68px;
  cursor: pointer;
}

.viewPhoto {
  max-width: 700px;
  max-height: 500px;
  margin: auto;
}

.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: auto;
  color: #95a2aa;
  line-height: 1.5;
}

.inputDiv{
  display: flex;
  justify-content: space-between;
}

.emailAddresses {
  width: 100%;
}

.inputDiv >  div:nth-child(1){
  display: flex;
  align-items: center;
}

.inputDiv >  div:nth-child(2){
  width: 70%;
}


.bodyOTP{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.resendLink{
  font-weight: bold;
  color: var(--color-sky);
  cursor: pointer;
  margin-left: 5px;
}

.resendLinkDisabled {
  color:linear-gradient(to right, #0054a7 -9%, #3a8dde);
  font-weight: bold;
  cursor: pointer;
  margin-left: 5px;
}

.didNotReceive{
  display: flex;
  gap: 2
}

.otpInputContainer {
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin: 10px auto;
}

.otpInput {
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  font-size: 18px;
  padding: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.otpInput:focus {
  outline: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.image {
  width: 200px;
}

.title {
  margin: 15px 0 15px 0;
}

.formContent{
  text-align: center;
}